import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

import StaffSlider from '../components/sliders/StaffSlider'

const CurrentDev = () => {
  return (
    <Layout>
      <Seo title="Current Dev" description="Gray and Sons Jewelry | Used rolex" noindex={true} />

      <div className="w-full max-w-[900px] aspect-video relative">
        <iframe
          src="https://player.vimeo.com/video/1048985484?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          title="Watch Repair - Audemars Piguet Royal Oak Chronograph Restored"
        ></iframe>
      </div>
      {/* <script src="https://player.vimeo.com/api/player.js"></script> */}

      <StaffSlider />
    </Layout>
  )
}

export default CurrentDev
